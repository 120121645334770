<template>
    <v-container>
        <v-card>
            <v-card-title class="pb-0 primary--text">Add Point Of Interest</v-card-title>
            <v-form ref="pointForm">
                <v-container>
                    <v-row>
                    <v-col
                    cols="12"
                    md="6"
                    class="pb-0"
                    >
                    <v-subheader class="pa-1 primary--text">Point Of Interest Name</v-subheader>
                    <v-text-field
                        v-model="pointData.name"
                        :rules="[() => !!pointData.name || 'This field is required']"
                        required
                        outlined
                        dense
                        autocomplete="off"
                    ></v-text-field>
                    </v-col>

                    <v-col
                    cols="12"
                    md="6"
                    class="pb-0"
                    >
                    <v-subheader class="pa-1 primary--text">Description</v-subheader>
                    <v-text-field
                        v-model="pointData.description"
                        :rules="[() => !!pointData.description || 'This field is required']"
                        required
                        outlined
                        dense
                        autocomplete="off"
                    ></v-text-field>
                    </v-col>

                    <v-col
                    cols="12"
                    md="6"
                    class="pb-0 pt-0"
                    >
                    <v-subheader class="pa-1 primary--text">Longitude</v-subheader>
                    <v-text-field
                        v-model="pointData.longitude"
                        :rules="[() => !!pointData.longitude || 'This field is required']"
                        required
                        outlined
                        dense
                        autocomplete="off"
                    ></v-text-field>
                    </v-col>

                    <v-col
                    cols="12"
                    md="6"
                    class="pb-0 pt-0"
                    >
                    <v-subheader class="pa-1 primary--text">Latitude</v-subheader>
                    <v-text-field
                        v-model="pointData.latitude"
                        :rules="[() => !!pointData.latitude || 'This field is required']"
                        required
                        outlined
                        dense
                        autocomplete="off"
                    ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" class="pb-0 pt-0">
                      <v-subheader class="pa-1 primary--text"
                        >Point of Interest Picture</v-subheader
                      >
                      <v-file-input
                        @change="uploadPictureInterest(uploadPic)"
                        v-model="uploadPic"
                        dense
                        outlined
                      ></v-file-input>
                    </v-col>


                    <!-- Submit Button -->
                     <v-col
                    cols="12"
                    md="12"
                    class="pb-2 pt-0"
                    >
                        <v-btn
                            class="mr-4 primary"
                            @click="postPointData"
                            >
                            submit
                        </v-btn>
                    </v-col>

                    </v-row>
                    
                    <v-dialog v-model="successDialog" persistent max-width="500px">
                        <v-card>
                            <v-card-title class="justify-center success white--text">Successful Add Point of Interest</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="justify-center success--text" text @click="close">Close</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    
                </v-container>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
      successMsg: '',
      successDialog: false,
      uploadPic: null,
      pointData: {
        name: '',
        description: '',
        longitude: '',
        latitude: '',
        picturePath: ''
      },

  }),

  methods: {

      

    postPointData() {
        const payload = {
            name: this.pointData.name,
            description: this.pointData.description,
            longitude: this.pointData.longitude,
            latitude: this.pointData.latitude,
            picturePath: this.pointData.picturePath,
        }

        console.log(payload);

        axios.post(`${process.env.VUE_APP_API_URL}/cms/addinterest`, payload,{
            headers: {
                Authorization: 'Bearer ' + this.$store.getters.user.token,
            },
        })
        .then((response) => {
            console.log(response.data)
            this.successDialog = true
            this.$refs.pointForm.reset();
        })
        .catch((error) => {
            console.log(error)
        })
    },

    close () {
        this.successDialog = false
    },

    uploadPictureInterest(data){

        console.log(data);

        let formData = new FormData();

        formData.append("files", data);

        axios.post(`https://temptunnel1.shaz.my/api/cms/upload`, formData, {
            headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
            },
        })
        .then((response) => {
            console.log(response);
            // this.stationData = response.data.filepath
            this.pointData.picturePath = response.data.filepath;
            console.log(this.pointData.picturePath);
        })
        .catch((error) => {
            console.log(error);
        });

    },

  }
}
</script>

<style lang="scss" scoped>


</style>